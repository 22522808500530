@import "../styles/utils/mixins", "../styles/utils/variables";

.careers-page {
  .hero-content-container {
    @include respond-to(small) {
      h1 {
        font-size: 60px;
        line-height: 60px;
      }
    }
  }

  .section-title {
    @include respond-to(small) {
      font-size: 40px;
      line-height: 40px;
    }
  }
}
.career-container {
  padding: 10px 0 120px;
  border-top: 13px solid $dark-purple;
  &:first-child {
    border-top: none;
  }

  .flex-container {
    align-items: flex-start;
  }

  p {
    font-size: 20px;
    line-height: 34px;
    &:first-child {
      margin-top: 0;
    }
    @include respond-to(small) {
      font-size: 16px;
      line-height: 22px;
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}

// Submit container
.submit-container {
  position: relative;
  padding-left: 50px;
  @include respond-to(small) {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &:before {
    content: "";
    background-color: $green;
    width: 3px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.error:before {
    background-color: rgb(185, 24, 24);
    height: 150px;
  }
  .submit-title {
    background-color: $green;
    padding: 50px;
    @include respond-to(small) {
      padding: 30px;
    }
  }
  .submit-title.error {
    background-color: rgb(184, 27, 27);
  }
  h3 {
    color: $white;
    font-size: 50px;
    font-weight: 400;
    line-height: 50px;
    margin: 0;
    text-transform: uppercase;
    @include respond-to(small) {
      font-size: 30px;
      line-height: 30px;
    }
  }
  .submit-content {
    background-color: $white;
    padding: 50px;
    @include respond-to(small) {
      padding: 30px;
    }

    .btn.error-btn {
      background-color: rgb(184, 27, 27);
      color: $white;
      padding: 15px;
      border: none;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      transition-duration: 0.4s;
    }

    h3 {
      color: $white;
      font-size: 50px;
      font-weight: 400;
      line-height: 50px;
      margin: 0;
      text-transform: uppercase;
      @include respond-to(small) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.sub-navigation {
  padding: 80px 0 50px;
  @include respond-to(small) {
    padding: 60px 0 40px;
  }

  li {
    border-left: 3px solid $orange;
    display: inline-block;
    list-style: none;
    padding: 6px 30px;
    margin-bottom: 30px;
    text-align: center;
    &:last-child {
      border-right: 3px solid $orange;
    }
    @include respond-to(small) {
      border-left: none;
      display: block;
      margin: 0 auto 20px;
      position: relative;
      &:last-child {
        border-right: none;
        &:after {
          content: "";
          background-color: $orange;
          display: block;
          width: 50px;
          height: 3px;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:before {
        content: "";
        background-color: $orange;
        display: block;
        width: 50px;
        height: 3px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    div {
      cursor: pointer;
      color: $black;
      font-family: "Nunito", sans-serif;
      font-size: 22px;
      font-weight: 700;
      line-height: 33px;
      &:hover {
        color: $orange;
      }
    }
  }
}

.indented-paragraph {
  text-indent: 20px; 
}

ul {
  &.indented-list {
    padding-left: 40px; 

    li {
      list-style-type: none;
      text-indent: -20px; 

      &:before {
        content: "•";
        padding-right: 10px;
      }
    }
  }
}

.error {
  color: red;
}

