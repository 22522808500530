@import "../../styles/utils/variables", "../../styles/utils/mixins";

.cookie-consent-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 0.4s ease-in-out forwards;
}
  
.cookie-consent {
  position: fixed;
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  left: min(30px,5%);
  bottom: min(30px,5%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px;
  animation: fadeIn 0.4s 0.1s ease-in-out forwards;
  opacity: 0;
  font-size: 18px;
  @include respond-to(small) {
    padding: 10px;
    font-size: 16px;
  }
}

.cookie-consent-content {
  text-align: center;
  h2 {
    margin-top: 0;
  }
  p {
    margin-bottom: 12px;
    font-size: inherit;
    line-height: 1.5;
  }
  button {
    border: none;
    transition: background 0.2s;
  }
}

:global(.cookie-consent-fadeout) {
  animation: fadeOut 0.4s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}