@import "../../styles/utils/variables", "../../styles/utils/mixins";

.our-clients-about-us-section {
  background-color: $creme-light;
  background-repeat: no-repeat;
  background-position: 200px 100px;
  background-size: auto 400px;
  padding: 150px 0;
  position: relative;

  @include respond-to(large) {
    padding: 80px 0;
    background-size: auto 300px;
  }
  @include respond-to(medium) {
    padding: 50px 0;
    background-size: auto 200px;
    background-position: 100px 100px;
  }
  @include respond-to(small) {
    background-position: 60px 100px;
  }

  .wrapper {
    max-width: 2400px;
    padding: 0 60px;
    @include respond-to(extra-large) {
      padding: 0 30px;
    }
    @include respond-to(small) {
      padding: 0 10px;
    }
  }

  h2 {
    margin: 0 0 30px;
    @include respond-to(medium) {
      font-size: 50px;
      text-align: center;
    }
  }
  h3,
  p {
    font-size: 24px;
    line-height: 1.2;
    @include respond-to(extra-large) {
      font-size: 20px;
    }
    @include respond-to(small) {
      font-size: 18px;
    }
  }

  :global .swiper {
    @include respond-to(large) {
      max-width: 600px;
      margin-left: 0;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-to(medium) {
    display: block;
  }
}

.our-clients-character {
  flex: 0 0 400px;
  @include respond-to(extra-large) {
    max-width: 300px;
  }
  @include respond-to(medium-large) {
    max-width: 200px;
  }
  @include respond-to(medium) {
    margin: 20px auto 0;
  }
}

.section-text {
  flex: 1;
  max-width: 1360px;
  padding-right: 20px;
  @include respond-to(medium) {
    padding-right: 0;
  } 
}