.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  width: 50px;
  height: 56px; 
  border-radius: 50%;
  background-color: #ff8c00;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff9f4d;
  }

  .arrow {
    position: center;
    padding-bottom: 6px;
    font-size: 2.4rem;
    z-index: 3;
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
